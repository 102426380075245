import { Cookies } from "react-cookie";
import qs from "qs";
import date from 'date-and-time';
import { AOIFOSTextMap, degreeLevel } from "./common/constants";
import { ProfanityList } from './common/ProfanityList';
import { restrictedCountries } from "./common/countriesList";

var b2bfullStoryData = {}
let cookie4employer = false;

/*export async function userAuthInfo () {
  const loginStatus = await window.EduAuth.getUserLoginStatus();
  console.log ("loginStatus", loginStatus);
  //if (loginStatus) {
    const userInfo = await window.EduAuth.getUserInfo();
    const personId = userInfo.personId;
    console.log ("userInfo", userInfo, personId);
    getUserFavoritePrograms(personId);
    emailAddress(personId);
    return userInfo;
  //}
}

export async function emailAddress(personId){
  const emailURL = process.env.REACT_APP_STAGE_API_URL +`/api/contacts/v1/emails?linkId=${personId}&linkType=person`;
   const emailResponse = await window.EduAuth.getDataFromMicroService(emailURL);
   console.log("emailResponse", emailResponse);
     
    if (emailResponse && emailResponse[0]){
        return emailResponse[0].emailAddress;
    } else {
        console.error('HTTP error: ', emailResponse.status, emailResponse.statusText);
        return;
    }
  }

export async function getUserFavoritePrograms(personId) {  
	//const leadsProfileProgramsURL = process.env.REACT_APP_STAGE_API_URL + `/api/leads/v2/profile/programs/${personId}`;
  let leadsProfileProgramsURL = `https://api.uopx.io/api/leads/v2/profile/programs/${personId}`;

  if (window.location.host != 'www.phoenix.edu') {
    leadsProfileProgramsURL = `https://api.st.uopx.io/api/leads/v2/profile/programs/${personId}`;
  }

  console.log ("leadsProfileProgramsURL", leadsProfileProgramsURL)
	const leadsProfileProgramsResponse = await getDataFromMS(leadsProfileProgramsURL);
	console.log ("leadsProfileProgramsResponse", leadsProfileProgramsResponse);
	// add nameResponse
	if(leadsProfileProgramsResponse && leadsProfileProgramsResponse[0]){
		// combine names and person id response
		//userInfo = {...nameResponse[0], ...userInfo};
		console.log("leadsProfileProgramsResponse[0].favorites", leadsProfileProgramsResponse.favorites[0], leadsProfileProgramsResponse.favorites[0].length);
	}else{
		// return only person id. do not store it in local storage
		return ;
	}
 }

  async function getDataFromMS(url) {
    try{
        const accessToken = await window.EduAuth.getAccessToken();
        if(!accessToken){
            return;
        }
        const fetchOptions = {
            method: "GET",
            headers: {
                "Authorization": "Bearer "+accessToken,
                "Accept": "application/json",
                "x-custom-headers": "leads-profile"
            }
        };
        const response = await fetch(url, fetchOptions);

        // Check if response is ok and parse the response data
        if (response.ok) {
            const data = await response.json();
            return data;
        } else {
            console.error('HTTP error: ', response.status, response.statusText);
            return;
        }

    }catch(err){
        console.log('error while calling microservice endpoint using GET method ', err);
        return;
    }
  }*/ 


export function userData(dataType) {
    var userDataObj = {};
    var deviceTypeValueRFI = true;
    var isMobileUser = {

        Android: function () {
            return navigator.userAgent.match(/Android/i);
        },
        BlackBerry: function () {
            return navigator.userAgent.match(/BlackBerry/i);
        },
        iOS: function () {
            return navigator.userAgent.match(/iPhone|iPad|iPod/i);
        },
        Opera: function () {
            return navigator.userAgent.match(/Opera Mini/i);
        },
        Windows: function () {
            return navigator.userAgent.match(/IEMobile/i);
        },
        any: function () {
            return (isMobileUser.Android() || isMobileUser.BlackBerry() || isMobileUser.iOS() || isMobileUser.Opera() || isMobileUser.Windows());
        }
    };

    if (isMobileUser.any()) {
        // deviceTypeValueRFI = deviceTypeValueRFI;
    } else {
        deviceTypeValueRFI = false;
    };

    userDataObj = {
        deviceType: deviceTypeValueRFI
    }

    if (dataType === "ipaddr") {
        console.log ("userDataObj.ipaddress in userData", userDataObj.ipaddress);
        return userDataObj.ipaddress;
    } else if (dataType === "deviceType") {
        return userDataObj.deviceType;

    }
}

export function makeid(length) {
    let result = '';
    let characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    let charactersLength = characters.length;
    for (var i = 0; i < length; i++) {
        result += characters.charAt(Math.floor(Math.random() * charactersLength));
    }
    return result;
}

/*
const getIPAddress = async (callback) => {
  /* const response = await fetch(
    `https://api.ipify.org?format=json`,
  ); 
  const response = await fetch(
    `https://www.phoenix.edu/services/contacts/v1/locationinfo?legacy=true&*cfheader=true*`,
  );
  if (response.status === 200) {
    const locationInfoObj = await response.json();
    callback(locationInfoObj);
  //  callback(ipObj.IPAddress.toString());
  }
}
*/

const getIPAddress = async (callback) => {
  const response = await fetch(
    `https://api.ipify.org?format=json`,
  );
  if (response.status === 200) {
    const ipObj = await response.json();
    callback(ipObj.ip.toString());
  }
}
export default getIPAddress;

export function getStateProvince(postalCode) {
/*  let zipcodeEndpoint = 'https://www.phoenix.edu/services/contacts/v1/validate/zipcode';

  if (window.location.host != 'www.phoenix.edu') {
    zipcodeEndpoint = 'https://stage.phoenix.edu/services/contacts/v1/validate/zipcode';
  }
    const response = await fetch(
      zipcodeEndpoint + `?zipcode=${postalCode}`,
    );
    if (response.status === 200) {
      const json = await response.json().then();
      if (json[0].zipcodes.length > 0){
        let stateCode = json[0].zipcodes[0].state_abbreviation;
        console.log ("state code in getStateProvince", stateCode);
        return stateCode;
      }
    } 
    return ''; */

    let baseUrl = process.env.REACT_APP_PHXEDU_BASE_URL;
    // console.log("Current env is: " + process.env.NODE_ENV);
    if (window.location.host !== 'www.phoenix.edu') {
        baseUrl = process.env.REACT_APP_PHXEDU_STAGE_BASE_URL;
    }
    fetch(baseUrl + '/services/contacts/v1/validate/zipcode' + `?zipcode=${postalCode}`, {
          method: 'GET',
    })
  //  .then(response => response===200)
    .then(response => response.json())
    .then(data => {
      if (data[0].zipcodes.length > 0){
        let stateCode = data[0].zipcodes[0].state_abbreviation;
        console.log ("state code in getStateProvince", stateCode);
        return stateCode;
      } else {
        return '';
      }
    })
    .catch(function (error) {
        console.log(error);
    });   
}

export function getRFIId() {
    const now = new Date();
    let timestamp = date.format(now, 'YYMMDDHHmmssSSS');
    let randomchar = makeid(5);
    let rfiId = timestamp + randomchar;
    return rfiId;
}

export function getmcid() {
    const cookies = new Cookies();
    let mcid_cookie = decodeURIComponent(cookies.get('AMCV_8DF667C25245B0070A490D4C%40AdobeOrg'));
    let mkid = mcid_cookie.split('|');
    // MCID value comes after MCMID in array. so get MCMID index and use this index to get mcid value
    if (mkid.length > 0) {
        var index = mkid.indexOf('MCMID');
        if (index !== -1) {
            return mkid[index + 1];
        }
    }
    return '';
}

export function loadLocalStorageData (initialValues) {
  if (window.localStorage.rfiData !== undefined && window.localStorage.rfiData.length > 0 ) {
    let rfiData = JSON.parse(window.localStorage.rfiData);
    //if (rfiData.isMobileDevice) {
      initialValues.firstName = rfiData.firstName;
      initialValues.lastName = rfiData.lastName;
      initialValues.emailAddress = rfiData.emailAddress;
      initialValues.phoneNumber = rfiData.phoneNumber;
      initialValues.postalCode = rfiData.postalCode;
      initialValues.stateProvince = rfiData.stateProvince;
      initialValues.isMilitary = rfiData.isMilitary;
      initialValues.isMobileDevice = rfiData.isMobileDevice;
      initialValues.fullName = rfiData.fullName;
      if (rfiData.fieldOfStudy !== 'other-programs' || rfiData.fieldOfStudy !== 'nondegree') {
        if (initialValues.degreeLevel === '') {
          initialValues.degreeLevel = rfiData.degreeLevel;
          initialValues.fieldOfStudy = rfiData.fieldOfStudy;
          initialValues.areaOfInterest = rfiData.area;
          initialValues.code = rfiData.code;
        }
        
      }
    //}
    return initialValues;
  }
}

export function loadCookiesData(initialValues) {
  // pre-populate form from localStorage values
  if (window.localStorage.seUserData !== undefined && window.localStorage.seUserData.length > 0) {
    let seData = JSON.parse(window.localStorage.seUserData);
    if (seData.hasMilitaryExperience) {
      initialValues.isMilitary = 'Yes';
    }
    if (window.localStorage.rfiData !== undefined && window.localStorage.rfiData.length > 0 ) {
      if(JSON.parse(window.localStorage.rfiData).fieldOfStudy !== 'other-programs' || JSON.parse(window.localStorage.rfiData).fieldOfStudy !== 'nondegree') {
        initialValues.degreeLevel = seData.degreeLevel;
      }
    }
  }

  loadLocalStorageData(initialValues);  
  /* if (window.localStorage.postalCode){
      initialValues.postalCode = window.localStorage.postalCode;
  }
  if (window.localStorage.state){
    initialValues.stateProvince = window.localStorage.state;
  } */
  

  // get url query params details
  const urlData = decodeURIComponent(window.location.href).split("?")[1];
  const urlqueryParams = qs.parse(urlData, { comma: true });
  //console.log("query params ", urlqueryParams);

  // get cookies details
  const cookies = new Cookies();
  let cookiesValue = cookies.get("ac_prepop");
  // const filtersCookiesParams = qs.parse(cookiesValue, { comma: true });

  if (cookiesValue) {
    let ac_prepopValues = decodeURIComponent(cookiesValue).split("&");

    for (let i = 0; i < ac_prepopValues.length; i++) {
      let key = '';
      let value = '';
      if (ac_prepopValues[i].split("=")[0] !== undefined && ac_prepopValues[i].split("=")[0] !== null){
         key = ac_prepopValues[i].split("=")[0].trim();   
      }
      if (ac_prepopValues[i].split("=")[1] !== undefined && ac_prepopValues[i].split("=")[1] !== null){
        value = ac_prepopValues[i].split("=")[1].trim();   
      }
      if (key.toLowerCase() === "additionalTracking".toLowerCase()) {
      key = ac_prepopValues[i].split("=")[1].trim();
      value = ac_prepopValues[i].split("=")[2].trim(); 
      //  console.log ("key and value for additional tracking  " + key + " " + value );
      }  
      if (value !== null && value !== undefined && value !== "undefined" && value !== '' && key !== null && key !== undefined && key !== "undefined" && key !== '' && initialValues.appContext !== 'EMAILCAPTURE') {
        if (key.toLowerCase() === "gclid".toLowerCase()) {
          initialValues.googleId = value;
          initialValues.flex_gclid__c = value;
        } else if (key.toLowerCase() === "gcid__c".toLowerCase()) {
          initialValues.flex_gcid__c = value;
        } else if (key.toLowerCase() === "fbclid".toLowerCase() || key.toLowerCase() === "fbclid__c".toLowerCase()) {
          initialValues.flex_fbclid__c = value;
        } else if (key.toLowerCase() === "s_kwcid".toLowerCase()) {
          initialValues.kwcId = value;
          initialValues.flex_kwcId__c = value;
        } else if (key.toLowerCase() === "pvp_campaign".toLowerCase()) {
            initialValues.campaign = value;
        } else if (key.toLowerCase() === "detail".toLowerCase()) {
            initialValues.campaign = value;
        } else if (key.toLowerCase() === "channel".toLowerCase()) {
            initialValues.source = value;
        } else if (key.toLowerCase() === "keyword".toLowerCase()) {
          initialValues.keyword = value;
          initialValues.flex_keyword__c = value;
        } else if (key.toLowerCase() === "provider".toLowerCase()) {
          initialValues.provider = value;
          initialValues.flex_provider__c = value;
        } else if (key.toLowerCase() === "user2".toLowerCase()) {
          initialValues.flex_user2__c = value;
        } else if (key.toLowerCase() === "mktg_prog".toLowerCase()) {
          initialValues.mktg_prog = value;
          initialValues.flex_mktg_prog__c = value;
        } else if (key.toLowerCase() === "degreeLevel".toLowerCase()) {
          if (initialValues.degreeLevel === '') {
            initialValues.degreeLevel = value;
          }
        } else if (key.toLowerCase() === "modality".toLowerCase()) {
          initialValues.modality = value;
        } else if (key.toLowerCase() === "fieldOfStudy".toLowerCase()) {
          if (initialValues.fieldOfStudy === '') {
            initialValues.fieldOfStudy = value;
          }
        } else if (key.toLowerCase() === "postalCode".toLowerCase() || key === "zipcode".toLowerCase()) {
        /*  if (initialValues.postalCode === '') {
            initialValues.postalCode = value;
          } */
        } else if (key.toLowerCase() === "stateProvince".toLowerCase() || key === "state".toLowerCase()) {
        /*  if (initialValues.stateProvince === '') {
            initialValues.stateProvince = value;
          } */
        } else if (key.toLowerCase() === "EmailCaptureSource".toLowerCase()) {
          initialValues.flex_EmailCaptureSource = value;
        } else if (key.toLowerCase() === "duns".toLowerCase()) {
          initialValues.flex_StudentDUNSNumber__c = value;
          initialValues.flex_DunsNumber__c = value;
        } else if (key.toLowerCase() === "companyName".toLowerCase()) {
          initialValues.flex_Employer_Disclosed_on_RFI__c = value;
        } else if (key.toLowerCase() === "employerName".toLowerCase()) {
          initialValues.flex_Employer_Disclosed_on_RFI__c = value;
        } else {
          initialValues[key] = value;
        }   
      }
      else if (key.toLowerCase() === "EmailCaptureSource".toLowerCase()) {
        initialValues.flex_EmailCaptureSource = value;
      }
    }
  }

  b2bfullStoryData.channel=initialValues.source;
  b2bfullStoryData.detail=initialValues.campaign;
  b2bfullStoryData.DUNS=initialValues.flex_DunsNumber__c;
  b2bfullStoryData.companyName=initialValues.flex_Employer_Disclosed_on_RFI__c;
  b2bfullStoryData.vertical=initialValues.vertical;
  b2bfullStoryData.companyType=initialValues.companyType;
  if (b2bfullStoryData.companyName !== null && b2bfullStoryData.companyName !== undefined && b2bfullStoryData.companyName !== "undefined" && b2bfullStoryData.companyName !== ''){
    cookie4employer = true;
  }
  //console.log ("b2bfullStoryData and cookie4employer", b2bfullStoryData, cookie4employer);

   

  Object.entries(urlqueryParams).map(([key, value]) => {
    const trimmedKey = key.toLowerCase().trim();
    if (value !== null && value !== undefined && value !== "undefined" && value !== '') {
      if (trimmedKey === "pvp_campaign".toLowerCase()) {
        initialValues.campaign = value;
      }
      if (trimmedKey === "detail".toLowerCase()) {
        initialValues.campaign = value;
      }
      if (trimmedKey === "channel".toLowerCase()) {
        initialValues.source = value;
      }
      if (trimmedKey === "source".toLowerCase()) {
        initialValues.source = value;
      }
      if (trimmedKey === "EmailCaptureSource".toLowerCase()) {
        initialValues.flex_EmailCaptureSource = value;
      }
      if (trimmedKey === "rrId".toLowerCase()) {
        initialValues.flex_referrer_analyticsId = value;
      }
      if (trimmedKey === "duns".toLowerCase()) {
        initialValues.flex_StudentDUNSNumber__c = value;
        initialValues.flex_DunsNumber__c = value;
      }
      if (trimmedKey === "accountName".toLowerCase()) {
        initialValues.flex_Employer_Disclosed_on_RFI__c = value;
      }
      if (trimmedKey === "user2".toLowerCase()) {
        initialValues.flex_user2__c = value;
      }
      else {
        if (trimmedKey != null) {
          initialValues[trimmedKey] = value.toString();
        }
      }
    }
  });
  // check if don't have degree and have field of study, the degree should be paying for school
  //if (initialValues.fieldOfStudy) {
  //  if (initialValues.degreeLevel) {
  //    initialValues.degree = "paying_for_school";
  //  } else {
   //   initialValues.degree = "degree_program";
  //  }
 // }
  // check zip code from session storage, if it has, it will use it firstly
/*  try {
    let sessionStorage = window.sessionStorage;
    let postalCode = sessionStorage.getItem("postalCode");
    if (postalCode !== undefined && postalCode !== "undefined" && postalCode !== null && postalCode !== '') {
      if (initialValues.postalCode === '') {
        initialValues.postalCode = postalCode;
      }
    }
  } catch (e) {
    // Catch error if access to session is disabled
    console.error(e);
  } */

/*      if (initialValues.postalCode !== '') {
        let baseUrl = process.env.REACT_APP_PHXEDU_BASE_URL;
        // console.log("Current env is: " + process.env.NODE_ENV);
        if (window.location.host !== 'www.phoenix.edu') {
            baseUrl = process.env.REACT_APP_PHXEDU_STAGE_BASE_URL;
        }
        fetch(baseUrl + '/services/contacts/v1/validate/zipcode' + `?zipcode=${initialValues.postalCode}`, {
              method: 'GET',
        })
        .then(response => response.json())
        .then(data => {
          console.log("data " + data);
          if (data[0].zipcodes.length > 0){
            let stateCode = data[0].zipcodes[0].state_abbreviation;
            initialValues.stateProvince = stateCode;
            initialValues.state=stateCode;
          } else {
            initialValues.stateProvince = '';
            initialValues.state='';
            initialValues.postalCode='';
            initialValues.zipcodes='';
          }
        })
        .catch(function (error) {
            console.log(error);
        }); 
      }
  */

  return initialValues;
};

function ucwords(str) {
  return (`${str}`).replace(/^([a-z])|\s+([a-z])/g, $1 => $1.toUpperCase());
}

  export function feedDataToSessionStorage(values) {
  //  const expiresAt = new Date();
  //  expiresAt.setHours(expiresAt.getHours() + 1);
    var rfiSessionItems = {
      firstName: values.firstName,
      lastName: values.lastName,
      emailAddress: values.emailAddress,
      phoneNumber: values.phoneNumber,
      postalCode: values.postalCode,
      stateProvince: values.stateProvince,
      isMilitary: values.isMilitary,
      areaOfInterest: values.area,
      code: values.code,
      degreeLevel: values.degreeLevel,
      fieldOfStudy: values.fieldOfStudy,
      isMobileDevice: values.isMobileDevice,
      fullName: values.fullName,
      highestLevelOfEducation: values.highestLevelOfEducation
    //  expiresAt: expiresAt
    }
    if (values.flex_Employer_Disclosed_on_RFI__c !== null || values.flex_Employer_Disclosed_on_RFI__c !== '', values.flex_Employer_Disclosed_on_RFI__c !== undefined || values.flex_Employer_Disclosed_on_RFI__c !== "null" || values.flex_Employer_Disclosed_on_RFI__c !== "undefined") {
      rfiSessionItems.employerName = values.flex_Employer_Disclosed_on_RFI__c;
    }
    let sessionStorage = window.sessionStorage;
    let localStorage = window.localStorage;
    sessionStorage.setItem("rfiSessionItems", JSON.stringify(rfiSessionItems));   
    localStorage.setItem("rfiData", JSON.stringify(rfiSessionItems));
    localStorage.setItem("postalCode", values.postalCode);
  }

  function getQuerystring (values) {
    let degreeLevel = values.degreeLevel;
    let fos = values.fieldOfStudy;
    if (values.flex_Info_Requested__c === "nondegree" || values.fieldOfStudy === "nondegree") {
        degreeLevel = "nondegree";
      }

    return '?degreeLevel=' + degreeLevel + '&orderID=' + values.rfiId + '&fos=' + values.fieldOfStudy + '&poi=' + values.code + '&rfi_complete=true&AppContext='
      + values.appContext + '&thankYouURL=' + encodeURIComponent(values.thankyouPageUrl) + '&status=success';
  }

  export function updatePrePopCookieValue(name, value) {
    let cookies = new Cookies();
    let cookiesValue = cookies.get("ac_prepop");

    if (cookiesValue) {
      let ac_prepopList = decodeURIComponent(cookiesValue).split("&");
      var index = ac_prepopList.findIndex(i => i.split("=")[0] === name);
      // if cookie exists, remove it and add it with new value
      if (index >= 0) {
        // delete entry from list
        ac_prepopList.splice(index, 1);
      }
      cookiesValue = ac_prepopList.join('&');
      // adding new cookie value
      if (value !== undefined && value !== "undefined" && value !== null && value !== ''){
        cookiesValue = cookiesValue.concat(`&` + name + '=' + value);
      }
    }
    if (cookiesValue !== undefined && value !== null && value !== ''){
      cookies.set('ac_prepop', cookiesValue, { domain: '.phoenix.edu', path: '/' });
    }
  }

  function setFirstNameCookie (firstName)  {
    const cookies = new Cookies();
    cookies.set('firstname', firstName, { path: '/' });
  }

  function removeEmptyProps (obj) {
    Object.keys(obj).forEach(key => (obj[key] === "" || obj[key] === undefined || obj[key] === null) && delete obj[key]);
    return obj;
  }


  export function submitForm (values, props) {
    Object.keys(values).map(k => values[k] = typeof values[k] == 'string' ? values[k].trim() : values[k]);
    if (values.flex_referrer_firstName !== "" && values.flex_referrer_firstName !== null && values.flex_referrer_firstName !== undefined && values.flex_referrer_firstName !== "undefined") {
      setFirstNameCookie(values.flex_referrer_firstName);
    } else {
      setFirstNameCookie(values.firstName);
    }
    let baseUrl = process.env.REACT_APP_PHXEDU_BASE_URL;
    // console.log("Current env is: " + process.env.NODE_ENV);
    if(window.location.host !== 'www.phoenix.edu') {
      baseUrl = process.env.REACT_APP_PHXEDU_STAGE_BASE_URL;
    }
    values.submissionTimestamp = date.format(new Date(), 'YYYY-MM-DDTHH:mm:ss.SSSZ');
    
  //  hashValues(values);
    mapAdditionalFields(values, props);
    feedDataToSessionStorage(values);
    const queryString = getQuerystring(values);
    //console.log ("form submit values" , values);
    //console.log ("form submit props ----- >" , JSON.stringify(props));
    if (values.isCBE) {
      fireCampaignEvent('11k campaign', 'CHECKED', formName, formType);
      window && window.utag && window.utag.link({
        tealium_event: '11kCampaigncheckbox',
        checkBoxSelect: 'CHECKED',
      });
    } else if (values.degreeLevel === 'masters' && (!values.isCBE || values.isCBE === '') && (values.fieldOfStudy === 'business' || values.fieldOfStudy === 'technology' || values.fieldOfStudy === 'healthcare')) {
      fireCampaignEvent('11k campaign', 'UNCHECKED', formName, formType);
      window && window.utag && window.utag.link({
        tealium_event: '11kCampaigncheckbox',
        checkBoxSelect: 'UNCHECKED',
      });
    } 
    
    fetch(baseUrl + '/services/sagas/v1/workflow/RFILead', {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify(removeEmptyProps(values))
    }).then(function (response) {
      //console.log(response);
      window.location.assign(baseUrl + values.thankyouPageUrl + queryString);
    }).catch(function (error) {
      console.log(error);
    });

    if (window.uopSegment) {
      window.uopSegment.track("RFI Submitted", {"firstName": values.firstName, "lastName": values.lastName, "email": values.emailAddress, "address": {"postalCode": values.postalCode}, "phone": values.phoneNumber})
    } 
    if (values.appContext !== 'EMAILCAPTURE'){
    //  fireEvent('rfi_submit');
    //  setTimeout(function(){ fireSubmitEvent ('RFISubmit', values, formName, formType); }, 200);
        fireSubmitEvent ('RFISubmit', values, formName, formType);
    }else {
    //  fireEvent('non_rfi_formComplete');
    //  setTimeout(function(){ fireNonRFISubmitEvent ('NonRFISubmit', values, formName, formType); }, 200);
        fireNonRFISubmitEvent ('NonRFISubmit', values, formName, formType);
    }
  }

  /*export function submitUnsubPhoneForm (values) {
    let baseUrl = process.env.REACT_APP_PHXEDU_BASE_URL;
    // console.log("Current env is: " + process.env.NODE_ENV);
    if(window.location.host !== 'www.phoenix.edu') {
      baseUrl = process.env.REACT_APP_PHXEDU_STAGE_BASE_URL;
    }
    values.submissionTimestamp = date.format(new Date(), 'YYYY-MM-DDTHH:mm:ss.SSSZ');
    values.requestingUrl = window.location.href;
    values.ipaddrs = getIPAddress();

    fetch(baseUrl + '/services/sagas/v1/workflow/PrefrenceCenterOptOut', {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify(removeEmptyProps(values))
    }).then(function (response) {
      console.log(response);
      window.location.assign(baseUrl + values.thankyouPageUrl);
    }).catch(function (error) {
      console.log(error);
    });

  }*/
    export async function submitUnsubPhoneForm(values) {
      let baseUrl = process.env.REACT_APP_PHXEDU_BASE_URL;
    // console.log("Current env is: " + process.env.NODE_ENV);
      if(window.location.host !== 'www.phoenix.edu') {
        baseUrl = process.env.REACT_APP_PHXEDU_STAGE_BASE_URL;
      }
  
      values.submissionTimestamp = date.format(new Date(), 'YYYY-MM-DDTHH:mm:ss.SSSZ');
      values.requestingUrl = window.location.href;
      //values.ipaddrs = getIPAddress();
  
      try {
          const response = await fetch(baseUrl + '/services/sagas/v1/workflow/PrefrenceCenterOptOut', {
              method: 'POST',
              headers: { 'Content-Type': 'application/json' },
              body: JSON.stringify(removeEmptyProps(values))
          });
  
          if (response.ok) {
              // Parse the JSON response if needed
              //const responseData = await response.json();
              console.log('Success:', response);
              return { success: true, message: "Thank you for your request! Please allow up to 6 hours for our system to be updated. We appreciate your patience."}; // Return success response data
          } else {
              const errorData = await response.json();
              throw new Error(errorData.message || 'An error occurred');
          }
      } catch (error) {
          console.log('Error:', error.message);
          return { success: false, message: error.message }; // Return an error object
      }
  }

  export function conditionalCBE(fos, dl) {
    let valid = false;
    if (dl === 'masters' && (fos === 'business' || fos === 'technology' || fos === 'healthcare')) {
      valid = true;
    }
    return valid;
  }

  export function roleChange() {
    let fieldSelect = document.getElementsByClassName('MuiSelect-select');
    let fieldSelectInput = document.getElementsByClassName('MuiSelect-nativeInput');
  //  console.log ("fieldSelect", fieldSelect.length );
    for(var i = 0; i < fieldSelect.length; i++) {
      fieldSelect[i].getAttribute('id');
 //    console.log ("fieldSelect get id ", fieldSelect[i].getAttribute('id'));
      fieldSelect[i].removeAttribute('role');     
      fieldSelect[i].setAttribute('role', 'listbox');
      fieldSelect[i].removeAttribute('aria-labelledby');
      fieldSelect[i].setAttribute('aria-label','please select ' + fieldSelect[i].getAttribute('id'));

      if(window.location.href.indexOf('/information-address') > -1){
        fieldSelect[i].removeAttribute('aria-label');
      }
    }
    for(var j = 0; j < fieldSelectInput.length; j++) {
      fieldSelectInput[j].removeAttribute('aria-hidden');     
    }
  }


  export function getPOI (values) {
    let field_of_study = values.fieldOfStudy, degree_level = values.degreeLevel;
    if (!values.fieldOfStudy) {
      field_of_study = "business";
    }
    if (!values.degreeLevel) {
      if (values.flex_Info_Requested__c === "nondegree" || values.fieldOfStudy === "nondegree") {
        degree_level = "nondegree";
      } else {
        degree_level = "bachelors";
      }
    }
    return programInterest(field_of_study, degree_level);
  }

  export function programInterest (e, f) {
    var b;
    if (e === "business") {
      if (f === "associates") {
        b = "AABFN"
      }
      if (f === "bachelors") {
        b = "BSB"
      }
      if (f === "masters") {
        b = "MBA"
      }
      if (f === "doctoral") {
        b = "DBA"
      }
      if (f === "certificate") {
        b = "CERT/MGT"
      }
      if (f === "ind-courses" || f === "nondegree") {
        b = "ND"
      }
    } else {
      if (e === "nursing") {
        if (f === "associates") {
          b = "UNKNOWN"
        }
        if (f === "bachelors") {
          b = "BSN"
        }
        if (f === "masters") {
          b = "MSN/ADM"
        }
        if (f === "doctoral") {
          b = "DNP"
        }
        if (f === "certificate") {
          b = "HCI"
        }
        if (f === "ind-courses" || f === "nondegree") {
          b = "ND"
        }
      } else {
        if (e === "technology") {
          if (f === "associates") {
            b = "AAITT"
          }
          if (f === "bachelors") {
            b = "BSIT"
          }
          if (f === "masters") {
            b = "MIS"
          }
          if (f === "doctoral") {
            b = "DM/IST"
          }
          if (f === "certificate") {
            b = "CERT/ASD"
          }
          if (f === "ind-courses" || f === "nondegree") {
            b = "ND"
          }
        } else {
          if (e === "communications") {
            if (f === "associates") {
              b = "UNKNOWN"
            }
            if (f === "bachelors") {
              b = "BS/COM"
            }
            if (f === "masters") {
              b = "MSPR"
            }
            if (f === "doctoral") {
              b = "DM"
            }
            if (f === "certificate") {
              b = "CERT/MGT"
            }
            if (f === "ind-courses" || f === "nondegree") {
              b = "ND"
            }
          } else {
            if (e === "criminal-justice") {
              if (f === "associates") {
                b = "AACJS"
              }
              if (f === "bachelors") {
                b = "BSCJA"
              }
              if (f === "masters") {
                b = "MS/AJS"
              }
              if (f === "doctoral") {
                b = "DM"
              }
              if (f === "certificate") {
                b = "CERT/CJM"
              }
              if (f === "ind-courses" || f === "nondegree") {
                b = "ND"
              }
            } else {
              if (e === "education") {
                if (f === "associates") {
                  b = "UNKNOWN"
                }
                if (f === "bachelors") {
                  b = "BSED/E"
                }
                if (f === "masters") {
                  b = "MAED/ADM"
                }
                if (f === "doctoral") {
                  b = "EDD"
                }
                if (f === "certificate") {
                  b = "CERT/SPE"
                }
                if (f === "ind-courses" || f === "nondegree") {
                  b = "COLEXT-ED"
                }
              } else {
                if (e === "healthcare") {
                  if (f === "associates") {
                    b = "UNKNOWN"
                  }
                  if (f === "bachelors") {
                    b = "BSHA"
                  }
                  if (f === "masters") {
                    b = "MHA"
                  }
                  if (f === "doctoral") {
                    b = "DHA"
                  }
                  if (f === "certificate") {
                    b = "CERT/MR"
                  }
                  if (f === "ind-courses" || f === "nondegree") {
                    b = "ND"
                  }
                } else {
                  if (e === "human-services") {
                    if (f === "associates") {
                      b = "UNKNOWN"
                    }
                    if (f === "bachelors") {
                      b = "BSHS"
                    }
                    if (f === "masters") {
                      b = "MSC/CCMH"
                    }
                    if (f === "doctoral") {
                      b = "DM"
                    }
                    if (f === "certificate") {
                      b = "CERT/HSM"
                    }
                    if (f === "ind-courses" || f === "nondegree") {
                      b = "ND"
                    }
                  } else {
                    if (e === "sciences") {
                      if (f === "associates") {
                        b = "UNKNOWN"
                      }
                      if (f === "bachelors") {
                        b = "BS/COM"
                      }
                      if (f === "masters") {
                        b = "MSP"
                      }
                      if (f === "doctoral") {
                        b = "DM"
                      }
                      if (f === "certificate") {
                        b = "CERT/HSM"
                      }
                      if (f === "ind-courses" || f === "nondegree") {
                        b = "ND"
                      }
                    } else {
                      if (e === "liberal-arts") {
                        if (f === "associates") {
                          b = "UNKNOWN"
                        }
                        if (f === "bachelors") {
                          b = "BA/ENG"
                        }
                        if (f === "masters") {
                          b = "UNKNOWN"
                        }
                        if (f === "doctoral") {
                          b = "UNKNOWN"
                        }
                        if (f === "certificate") {
                          b = "Cert"
                        }
                        if (f === "ind-courses" || f === "nondegree") {
                          b = "ND"
                        }
                      } else {
                        if (e === "other-programs" || e === "nondegree") {
                          if (f === "associates") {
                            b = "UNKNOWN"
                          } else if (f === "bachelors") {
                            b = "UNKNOWN"
                          } else if (f === "masters") {
                            b = "UNKNOWN"
                          } else if (f === "doctoral") {
                            b = "UNKNOWN"
                          } else if (f === "certificate") {
                            b = "CERT/MGT"
                          } else if (f === "ind-courses" || f === "nondegree") {
                            b = "ND"
                          }
                        } else {
                          if (e === "undecided") {
                            if (f === "associates") {
                              b = "UNKNOWN"
                            }
                            if (f === "bachelors") {
                              b = "UNKNOWN"
                            }
                            if (f === "masters") {
                              b = "UNKNOWN"
                            }
                            if (f === "doctoral") {
                              b = "UNKNOWN"
                            }
                            if (f === "certificate") {
                              b = "CERT/MGT"
                            }
                            if (f === "ind-courses" || f === "nondegree") {
                              b = "ND"
                            }
                          } else {
                            if (e === "psychology") {
                              if (f === "associates") {
                                b = "UNKNOWN"
                              }
                              if (f === "bachelors") {
                                b = "BSIOP"
                              }
                              if (f === "masters") {
                                b = "MSP"
                              }
                              if (f === "doctoral") {
                                b = "UNKNOWN"
                              }
                              if (f === "certificate") {
                                b = "UNKNOWN"
                              }
                              if (f === "ind-courses" || f === "nondegree") {
                                b = "ND"
                              }
                            } else {
                              if (e === "behavioral-sciences") {
                                if (f === "associates") {
                                  b = "UNKNOWN"
                                }
                                if (f === "bachelors") {
                                  b = "BSCPSS"
                                }
                                if (f === "masters") {
                                  b = "MSC/CCMH"
                                }
                                if (f === "doctoral") {
                                  b = "UNKNOWN"
                                }
                                if (f === "certificate") {
                                  b = "UNKNOWN"
                                }
                                if (f === "ind-courses" || f === "nondegree") {
                                  b = "ND"
                                }
                              }
                            }
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
    return b
  }

  export function mapAdditionalFields (values, props){

    values.requestingUrl = window.location.href;
    values.referringUrl = document.referrer;
    values.userAgent = window.navigator.userAgent;
    values.isMobileDevice = userData("deviceType");
    values.mcid = getmcid();
   // values = loadCookiesData(values);
    values.rfiId = getRFIId();
    var seData;
    const AEMfieldName = ['title', 'theme', 'fieldsHidden', 'fieldsEnabled', 'fieldOfStudyOptions', 'degreeLevelOptions', 'classes', 'fieldOfStudy', 'isRegisteredNurse', 'degreeLevel', 'postalCode', 'thankyouPageUrl', 'education',]
    if (window.analytics !== undefined) {
      if (window.analytics.user().anonymousId() !== null && window.analytics.user().anonymousId() !== undefined){
        values.segmentAnonymousId = window.analytics.user().anonymousId();
      }
      if (window.analytics.user().id() !== null && window.analytics.user().id() !== undefined){
        values.segmentUserId = window.analytics.user().id();
      }
    }
    if (values.appContext !== 'EMAILCAPTURE'){
      let field_of_study = values.fieldOfStudy ? values.fieldOfStudy : 'business';
      if (values.fieldOfStudy === 'nondegree'){
        values.degreeLevel = 'nondegree';
      } 
      if (values.isScholarship === 'Yes' || values.isScholarship === 'true' || values.isScholarship === true) { 
        if(values.flex_Info_Requested__c === '') {
          values.flex_Info_Requested__c = 'scholarship';
        } else {
          values.flex_Info_Requested__c = values.flex_Info_Requested__c + ', scholarship';
        }
      } 
      if (values.isEmployer === 'Yes' || values.isEmployer === 'true' || values.isEmployer === true) {
        if(values.flex_Info_Requested__c === '') {
          values.flex_Info_Requested__c = 'employer_tuition_discounts';
        } else {
          values.flex_Info_Requested__c = values.flex_Info_Requested__c + ', employer_tuition_discounts';
        }
      }
      if (values.flex_Info_Requested__c === "nondegree"){
        values.area = values.area;
      } else{
        values.area = AOIFOSTextMap[field_of_study] || 'Business and Management';
      }
    /*  if (values.code === ''){
      } */
      values.code = getPOI(values);
      if (values.campaign === 'REFR') {
        values.code = 'UNKNOWN'
      }
      if (values.isCBE) {
        if (values.fieldOfStudy === "business"){
          values.code ='MBA-CB';
        //  values.thankyouPageUrl = '/request-info/thank-you.html';
        }
        if (values.fieldOfStudy === "technology"){
          values.code ='MIS-CB';
        //  values.thankyouPageUrl = '/request-info/thank-you.html';
        }
        if (values.fieldOfStudy === "healthcare"){
          values.code ='MHA-CB';
        //  values.thankyouPageUrl = '/request-info/thank-you.html';
        }
      //  values.thankyouPageUrl = '/request-info/thank-you-cbe.html';
      }
      /*
      if (values.degreeLevel === 'masters' && values.isMobileDevice === true && (!values.isCBE || values.isCBE === '')) {
        values.thankyouPageUrl = '/request-info/thank-you.html';
      } 
      if (values.fieldOfStudy === "technology" && values.flex_rfiCallback === ''){
        values.flex_rfiCallback = false;
      }*/
      if (values.degreeLevel === 'associates' && values.fieldOfStudy === "liberal-arts" && values.fosisdisable === 'true' && values.dlisdisable === 'true'){
          values.code = 'AA/PF';
      }
      if (values.degreeLevel === 'associates' && values.fieldOfStudy === "nursing" && values.isRegisteredNurse === 'Yes'){
        values.code = 'BSN';
      }
      if (values.degreeLevel === 'nondegree' || values.flex_EmailCaptureSource === 'ND'){
        values.thankyouPageUrl = '/request-info/course-thank-you.html'
      }
      if(values.flex_Course_Category__c==='continuing-teacher-education'){
        values.code ='COLEXT-ED';
      }
      if(values.flex_Course_Category__c ==='nursing'){
        values.code ='ND-NUR';
      }
      if (values.source.length > 4){
        values.source = 'OVER';
      }
      values.degreeLevel ? values.degreeLevel : 'bachelors';
      if (!window.localStorage.isSePassedRFI || window.localStorage.isSePassedRFI === undefined){
        if (window.localStorage.seUserData !== undefined && window.localStorage.seUserData.length > 0) {
          seData = JSON.parse(window.localStorage.seUserData);
          if (seData.opportunities && seData.opportunities.length > 0) {
            values.flex_Savings_Explorer__c = seData.opportunities;
          }
          localStorage.setItem("isSePassedRFI", true);
        }
      }
      if(typeof props !== 'undefined') {
      Object.keys(props).forEach(propsKey => {
        //  console.log ("propsKey and Object.keys(values)", propsKey, Object.keys(values));
        if (AEMfieldName.indexOf(propsKey) === -1) { 
          //console.log("in if props not in AEMfieldName array --- > ", propsKey);
          if (props[propsKey] !== "" || props[propsKey] !== undefined || props[propsKey] !== null) {
            if (!Object.keys(values).includes(propsKey)){
              //console.log("props not in initialValues  --- > ", propsKey);
              values[propsKey] = props[propsKey];
              //console.log("add props keyValue to initialValues ", values[propsKey],  props[propsKey]);
            }
          } 
        }
      }); 
      }
    }
    return values;
  }
/*
export function getEventName(step) {
  let name;
  switch (step) {
    case 1:
      name = 'rfi_step1';
      break;
    case 2:
      name = 'rfi_step2';
      break;
    case 3:
      name = 'rfi_step3';
      break;
    case 4:
      name = 'rfi_step4';
      break;
    case 5:
      name = 'rfi_step5';
      break;
    case 6:
      name = 'rfi_submit';
      break;
  }
  return name;
} */

export function pageNameByPath(){
  let pageName =  '';
  let prefix = 'mktg:rfi';
  let pathFormatted = window.location.pathname.replace(/\//g, ':');
	pageName = prefix + pathFormatted;
  //console.log ("pageName " + pageName);
	//	return pageName;
    if (window.utag_data) {
      window.utag_data.pageName = pageName;
    }
  }

export function fireEvent(eventName, componentName, formName, formType, formText, formLocation){
  //  console.log("firing event name: " + eventName);
    if (window.uopxDataLayer && window.uopxDataLayer.track) {
      window.uopxDataLayer.track(eventName, {
        "componentName" : componentName,
        "name": formName, 
        "type": formType,
        "text": formText,
        "location": formLocation,
      });
    }
  //  console.log ("fireAnalyticsEvent", eventName); 
    window.uopSegment && window.uopSegment.track("RFI Event", {"status": eventName, "rfiFormType": formType, "rfiFormName": formName});
  }  

export function fireAnalyticsEvent(eventName, formName, formType){
  //  console.log("firing event name: " + eventName);
    if (window.uopxDataLayer && window.uopxDataLayer.track) {
      window.uopxDataLayer.track(eventName, {
        "componentName" : "RequestInfo",
        "name": formName, 
        "type": formType,
      });
    }
  //  console.log ("fireAnalyticsEvent", eventName); 
    window.uopSegment && window.uopSegment.track("RFI Event", {"status": eventName, "rfiFormType": formType, "rfiFormName": formName});
  }

export function fireFOS_DlEvent(eventName, eventValue, formName, formType) {
  let eventKey = "rfi_"+ eventName;
  if (eventValue && eventName && window.uopxDataLayer && window.uopxDataLayer.track) {
    let formDetailsKeyValues = {
      "componentName" : "RequestInfo",
      "formInformation": {
        "formDetails": {
        },
      },
      "name": formName, 
      "type": formType,
    }
    formDetailsKeyValues.formInformation.formDetails[eventName] = eventValue;
    window.uopxDataLayer.track("RequestInfo", formDetailsKeyValues);
  //  console.log ("fireFOS_DlEvent", eventName, eventValue);
  }
  window.uopSegment && window.uopSegment.track("RFI Event", {[eventKey]: eventValue});
}

export function fireRFISavingsOptionEvent( eventValue, formName, formType) {
  if (eventValue && window.uopxDataLayer && window.uopxDataLayer.track) {
    window.uopxDataLayer.track("RFISaveMoney",{
      "componentName" : "Request Info",
      "name": formName, 
      "type": formType,
      "formInformation": {
        "formDetails": {
          "rfiSavingsOption": eventValue,
        }
      }
    });
  //  console.log ("fireRFISavingsOptionEvent", eventValue);
  }
}

export function fireCampaignEvent( campaignName, eventValue, formName, formType) {
  if (campaignName && eventValue && window.uopxDataLayer && window.uopxDataLayer.track) {
    window.uopxDataLayer.track("RequestInfoCampaign", {
      "componentName" : "RequestInfo",
      "formInformation": {
        "formDetails": {
        "campaign": campaignName,
        "campaignOption" : eventValue,
        },
      },
      "name": formName, 
      "type": formType,
    });
  //  console.log ("campaign ", campaignName, eventValue);
  }
}

export function fireCampaignRadioEvent( campaignName, eventValue, formName, formType) {
  if (campaignName && eventValue && window.uopxDataLayer && window.uopxDataLayer.track) {
    uopxDataLayer.track("RequestInfoCampaignRadio", {
      "componentName" : "RequestInfo",
      "formInformation": {
        "formDetails": {
          "campaign": campaignName,
          "campaignOption": eventValue,
        },
      },
      "name": formName, 
      "type": formType,
    });
  }
}

export function fireSubmitEvent(eventName, values, formName, formType ) { 
  //console.log ("values", values, values.flex_Employer_Disclosed_on_RFI__c);
  if (eventName && window.uopxDataLayer && window.uopxDataLayer.track) {
    let submitEventData = {
      "componentName" : "RequestInfo",
      "name": formName, 
      "type": formType,
      "formInformation" : {
        "formDetails": {
          "fieldOfStudy": values.fieldOfStudy,
          "degreeType": values.degreeLevel,
          "highestLevelOfEducation":values.highestLevelOfEducation,
        },
        "profileDetails": {
          "firstName" : values.firstName,       //sha-256 hash
          "lastName": values.lastName,
          "email": values.emailAddress,
          "phone": values.phoneNumber, 
          "address": {
            "postalCode": values.postalCode,
            "state": values.stateProvince,
          },
          "isMilitary":values.isMilitary,
          "rfiOrderID": values.rfiId,
        },
      },
    }
  //  setTimeout(function(){  }, 2000);
  //  console.log ("fireSubmitEvent ", JSON.stringify(submitEventData));
    window.uopxDataLayer.track(eventName, submitEventData);
  }
  window.uopSegment && window.uopSegment.track("RFI Event", {"status": eventName, "rfiFormType": formType, "rfiFormName": formName});
  
  if (window.FS && cookie4employer) {
    //calling FS and passing the tracking data set
    window.FS.event('Submitted B2B_RFI_AlliancePage', b2bfullStoryData);
    b2bTracking (values, b2bfullStoryData);
  //  console.log("B2B_RFI_AlliancePage and cookie4employer", b2bfullStoryData, cookie4employer);
  }
  if (window.FS && !cookie4employer && values.flex_Employer_Disclosed_on_RFI__c !== '' && values.flex_Employer_Disclosed_on_RFI__c !== null && values.flex_Employer_Disclosed_on_RFI__c !== undefined && values.flex_Employer_Disclosed_on_RFI__c !== "undefined") {
    b2bfullStoryData.channel=values.source;
    b2bfullStoryData.detail=values.campaign;
    b2bfullStoryData.DUNS=values.flex_DunsNumber__c;
    b2bfullStoryData.companyName=values.flex_Employer_Disclosed_on_RFI__c;
    b2bfullStoryData.vertical=values.vertical;
    b2bfullStoryData.companyType=values.companyType;
    window.FS.event('Submitted B2B_RFI_Non_AlliancePage', b2bfullStoryData);
    b2bTracking (values, b2bfullStoryData);
  //  console.log("B2B_RFI_Non_AlliancePage and cookie4employer", b2bfullStoryData, cookie4employer);
  }
  if (window.FS && (values.flex_Employer_Disclosed_on_RFI__c === '' || values.flex_Employer_Disclosed_on_RFI__c === null || values.flex_Employer_Disclosed_on_RFI__c === undefined || values.flex_Employer_Disclosed_on_RFI__c === "undefined")) {
    b2bfullStoryData.companyName = "No_Employer_Entered_RFI";
    //calling FS and passing the tracking data set
    window.FS.event('Submitted B2B_RFI_No_Employer', b2bfullStoryData);
  //  console.log("B2B_RFI_No_Employer", b2bfullStoryData);
  }
}

export function fireNonRFISubmitEvent(eventName, values, formName, formType) {
/*  console.log ("hashFirstName", hashFirstName, hashLastName, hashEmail);
  if ((hashFirstName || hashLastName || hashEmail) === undefined) {
    hashValues(values);
  } 
    setTimeout(function(){    */
      if (eventName && window.uopxDataLayer && window.uopxDataLayer.track) {
        window.uopxDataLayer.track(eventName,{
          "componentName" : "RequestInfo",
          "name": formName, 
          "type": formType,
          "formInformation" : {
            "profileDetails":{
              "firstName" : values.firstName,       //sha-256 hash
              "lastName": values.lastName,
              "email": values.emailAddress,
            },
          },
        });
      //  console.log ("fireNonRFISubmitEvent", values);
      }
  //  }, 200);
    window.uopSegment && window.uopSegment.track("RFI Event", {"status": eventName, "rfiFormType": formType, "rfiFormName": formName});
}

function b2bTracking(values, b2bfullStoryData) {
  if (window.uopxDataLayer && window.uopxDataLayer.track) {
    let b2bSubmitEventData = {
      "alliance": {
        "channel" : b2bfullStoryData.channel,
        "companyName" : b2bfullStoryData.companyName,
        "companyType" : b2bfullStoryData.companyType,  
        "detail" : b2bfullStoryData.detail,
        "duns" : b2bfullStoryData.DUNS,
        "vertical" : b2bfullStoryData.vertical,
      },
    }
    console.log ("B2B RFI tracking ", JSON.stringify(b2bSubmitEventData));
    window.uopxDataLayer.track("B2B RFI tracking", b2bSubmitEventData);
  }
}

/*
export function fireEvent(eventName){
  //  console.log("firing event name: " + eventName);
    window && window.utag && window.utag.link({
      tealium_event: eventName
    });
    window.uopSegment && window.uopSegment.track("RFI Event", {"status": eventName, "rfiFormType":window.utag_data.rfiFormType, "rfiFormName":window.utag_data.rfiFormName }); 
  }

export function fireInterestEvent(value) {
  if (value && window && window.utag) {
    window.utag.link({
      tealium_event: 'get_rfi_dropdown',
      rfi_interest: value
    })
  }
  window.uopSegment && window.uopSegment.track("RFI Event", {"rfi_interest": value});
}

export function fireFOSEvent(value) {
  if (value && window && window.utag) {
    window.utag.link({
      tealium_event: 'rfiFieldOfStudy',
      fieldOfStudy: value
    });
  }
  window.uopSegment && window.uopSegment.track("RFI Event", {"rfi_fieldOfStudy": value});
}

export function fireDegreeLevelEvent(value) {
  if (value && window && window.utag) {
    window.utag.link({
      tealium_event: 'get_rfi_dropdown',
      rfi_degreelevel: value
    });
  //  console.log ("degree Level event fired", value);
  }
  window.uopSegment && window.uopSegment.track("RFI Event", {"rfi_degreelevel": value});
}
*/

export function profanityCheck(value) {
  let Plist = ProfanityList
  const foundPlist = Plist.filter(word => new RegExp("\\b" + escapeRegExp(word) + "\\b", 'i').test(value));
  if(foundPlist.length){
      // console.log("Profanity Check: ", ...foundPlist);
      return false;
  }
  return true;
}
function escapeRegExp(text) {
  return text.replace(/[-[\]{}()*+?.,\\^$|#\s]/g, '\\$&');
}

export function isUserEligible(selectedCountryCode, isMilitary, isUSCitizen) {
  var isEligible = true;
  var isUserFromRestrictedCountry = restrictedCountries.filter(function (country) {
    return country.value === selectedCountryCode;
  }).length > 0;
  if (isUserFromRestrictedCountry && isMilitary === "No" && isUSCitizen === "No") {
    isEligible = false;
  }
  return isEligible;
}

export function scrollToTop() {
  window.scrollTo(0,0);
}

export function getDegreeLevel(initialDegreeLevels,FOSvalue, isRegisteredNurse="Yes") {
  let newDegreeLevelOptions = [];
  switch (FOSvalue) {   
    case 'nursing':
        // If RN is Yes - All options except associates, otherwise only list Single course
        newDegreeLevelOptions.splice(0, newDegreeLevelOptions.length);                   
        initialDegreeLevels.forEach(e => {
            if ((isRegisteredNurse==="Yes" && e.label !== 'Associate') || e.label ==='Single course') newDegreeLevelOptions.push(e);
        })         
      break;         
    case 'healthcare':
        // All options except associates
        newDegreeLevelOptions.splice(0, newDegreeLevelOptions.length);                   
        initialDegreeLevels.forEach(e => {
            if (e.label!=="Associate") newDegreeLevelOptions.push(e);
        })         
      break;    
    case 'technology':
        // All options except doctorates
        newDegreeLevelOptions.splice(0, newDegreeLevelOptions.length);
        initialDegreeLevels.forEach(e => {
            if (e.label!=='Doctorate') newDegreeLevelOptions.push(e);
        })      
      break;
    case 'criminal-justice':
        // All options except doctorates and certificate
        newDegreeLevelOptions.splice(0, newDegreeLevelOptions.length);
        initialDegreeLevels.forEach(e => {
        //  console.log(e.label);
            if (e.label!=='Doctorate' && e.label!=='Certificate') newDegreeLevelOptions.push(e);
        })      
      break;
    case 'education':
        // All options except associates
        newDegreeLevelOptions.splice(0, newDegreeLevelOptions.length);
        initialDegreeLevels.forEach(e => {
            if (e.label!=='Associate') newDegreeLevelOptions.push(e);
        })      
      break;            
    case 'liberal-arts':
        // Only Single course and Bachelor
        newDegreeLevelOptions.splice(0, newDegreeLevelOptions.length);
        initialDegreeLevels.forEach(e => {
            if (e.label==='Single course'|| e.label==='Bachelors') newDegreeLevelOptions.push(e);
        })        
      break;
    case 'psychology':
        // Only show masters, bachelors and individual courses
        newDegreeLevelOptions.splice(0, newDegreeLevelOptions.length);                   
        initialDegreeLevels.forEach(e => {
            if (e.label!=='Associate' && e.label!=='Doctorate' && e.label!=='Certificate') newDegreeLevelOptions.push(e);
        })         
      break;  
    case 'behavioral-sciences': 
        // Only show masters, bachelors and individual courses
        newDegreeLevelOptions.splice(0, newDegreeLevelOptions.length);                   
        initialDegreeLevels.forEach(e => {
            if (e.label!=='Associate' && e.label!=='Doctorate' && e.label!=='Certificate') newDegreeLevelOptions.push(e);
        })      
      break;
    case 'other-programs': 
        // Only show individual courses
        newDegreeLevelOptions.splice(0, newDegreeLevelOptions.length);                   
        initialDegreeLevels.forEach(e => {
            if (e.label==='Individual courses') newDegreeLevelOptions.push(e);
        })      
      break;
    default:
        newDegreeLevelOptions = initialDegreeLevels;        
      break;
  }
  return newDegreeLevelOptions;
}

/*
export function hashValues(values){
  if (values.firstName && values.lastName && values.emailAddress){
    hash(values.firstName).then(response => {hashFirstName = response;});
    hash(values.lastName).then(response => {hashLastName = response;});
    hash(values.emailAddress).then(response => {hashEmail = response;});
  }
  if (values.phoneNumber !== undefined || values.phoneNumber !== null || values.phoneNumber !== ''){ 
    hash(values.phoneNumber).then(response => {hashPhone = response;});
  }
}

 export function hash(string) {
  const utf8 = new TextEncoder().encode(string);
  return crypto.subtle.digest('SHA-256', utf8).then((hashBuffer) => {
    const hashArray = Array.from(new Uint8Array(hashBuffer));
    const hashHex = hashArray
      .map((bytes) => bytes.toString(16).padStart(2, '0'))
      .join('');
  //  console.log ("fireSubmitEvent hashHex", string, hashHex);
    return hashHex;
  });
} 


async function hash(string) {
  const utf8 = new TextEncoder().encode(string);
  const hashBuffer = await crypto.subtle.digest('SHA-256', utf8);
  const hashArray = Array.from(new Uint8Array(hashBuffer));
  const hashHex = hashArray
    .map((bytes) => bytes.toString(16).padStart(2, '0'))
    .join('');
  return hashHex;
} */

export function unescapeHTML(html) {
  //console.log ("html", html);
  if (html !== '' && html !== null && html !== undefined) {
    if (html.indexOf('<') === -1) {
      return html;
    }
  }
  // eslint-disable-next-line react/no-danger
  return <div dangerouslySetInnerHTML={{ __html: html }} />;
}

export function srSpeak(text, priority) {
  try{
      var el = document.createElement("div");
      var id = "make-a-screen-reader-talk-" + Date.now();
      el.setAttribute("id", id);
      el.setAttribute("aria-live", priority || "polite");            
      el.classList.add("visually-hidden");
      document.body.appendChild(el);
      
      window.setTimeout(function () {
          document.getElementById(id).innerHTML = text;      
      }, 100);
      
      window.setTimeout(function () {
          if (document.getElementById(id)){
              document.body.removeChild(document.getElementById(id));
          }
      }, 1000);
  } catch(e){
      console.log ("error", e);
      throw e;
  }
}