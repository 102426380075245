import React, {Suspense} from 'react';
import { Navigate, Route, Routes,} from 'react-router-dom';
import { createGenerateClassName, StylesProvider } from '@mui/styles/';
import DynamicDesktopRFI from './components/DynamicOneStep';
import DesktopRFI from './components/RFIDesktopEmbed';
import RFIHumanizing from './components/RFIDesktop';
import Emailus from './components/email-us/Emailus';
import UnsubscribeForms from './components/unsubscribe/UnsubscribeForms';
import IndividualEmailUnsubscribe from './components/unsubscribe/IndividualEmailUnsubscribe';
import SubscribeEmailForm from './components/inline/SubscribeEmailForm';
import SimpleApplyNow2StepSmarty from './components/inline/SimpleApplyNow2StepSmarty';
import ReferAFriend from './components/refer-a-friend/ReferFriend';
import Abandoned from './components/email-us/Abandoned';
import BusinessGuideInline from './components/email-us/BusinessGuideInline';
import BusinessGuide from './components/email-us/BusinessGuide';
import BusinessGuideAbandonment from './components/email-us/BusinessGuideAbandonment';
import RFIAbandoned from './components/email-us/EmailSubscribe';
import ScholarshipInline from './components/email-us/ScholarshipsInline';
import ScholarshipBladeModal from './components/email-us/ScholarshipBladeModal';
import ScholarshipExitModal from './components/email-us/ScholarshipExit';
import ZeroPartyModal from './components/email-us/ZeroPartyModal';
import EmailSubscribeFooter from './components/email-us/EmailSubscribeFooter';
import UopxContact from './components/email-us/UopxContact';
import AlumniContactForm from './components/email-us/AlumniContactForm';
import AlumniStory from './components/email-us/AlumniStory';
import DynamicRFIInlineForm from './components/inline/DynamicRFIInlineForm';
import EmployerDynamicRFIInlineForm from './components/inline/EmployerDynamicRFIInlineForm';
import PaidMediaEmployerRFIInlineForm from './components/inline/PaidMediaEmployerRFIInlineForm';
import InternationalStudentRFI from './components/international-student/InternationalStudent';
import ContinuingEducation from './components/continuing-education/ContinuingEducation';
import MRFIDynamic5Step from './components/MobileRFI/MRfiDynamicFiveStep';
import MRFIScheduledCall from './components/MobileRFI/MrfiScheduleCall';
import Summit from './components/email-us/SummitEmail';
import AapfRfi from './components/AAPFRfi';
import RFIDatePicker from './components/RFIDatePicker';
import AlumniBusinessForm from './components/email-us/AlumniBusinessForm';
import White2Step from './components/inline/White2Step';
import ReEntryContactUs from './components/email-us/ReEntryContactUs';
import AlumniChroniclesMagazine from './components/email-us/AlumniChroniclesMagazine';
import CommunityCollegeTransferForm from './components/inline/CommunityCollegeTransferForm';
import EmployerReferralForm from './components/EmployerReferralForm';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import './page.css';
import './App.scss';
import TransferCreditCollegeGuideForm from './components/inline/TransferCreditCollegeGuideForm';
import TransferCreditGuideAbandonment from './components/inline/TransferCreditGuideAbandonment';
import WSGEmailPhoneCaptureForm from './components/email-us/EmailPhoneSubscribeWSG';
import ScheduleACall from './components/inline/ScheduleACall';
import ScheduleACallDropdown from './components/inline/ScheduleACallDropdown';
import GatedContentForm from './components/email-us/GatedContentForm';
import ZeroPartyBGVE from './components/email-us/ZeroPartyBGVE';
import TransferCreditGuideVE from './components/inline/TransferCreditGuideVE';
import PhoenixLuminary  from './components/email-us/PhoenixLuminary';
import OptOutForms from './components/unsubscribe/OptOutForms';
const theme = createTheme();
const generateClassName = createGenerateClassName({
  // seed: 'RFIReactApp',
});

//const DynamicDesktopRFI = React.lazy(() => import('./components/DynamicOneStep' /* webpackChunkName: "DynamicDesktopRFI" */));
//const Emailus = React.lazy(() => import('./components/email-us/Emailus' /* webpackChunkName: "Emailus" */));
//const UnsubscribeForms = React.lazy(() => import('./components/unsubscribe/UnsubscribeForms' /* webpackChunkName: "UnsubscribeForms" */));
//const SubscribeEmailForm = React.lazy(() => import('./components/inline/SubscribeEmailForm' /* webpackChunkName: "SubscribeEmailForm" */));
//const SimpleApplyNow2StepSmarty = React.lazy(() => import('./components/inline/SimpleApplyNow2StepSmarty' /* webpackChunkName: "SimpleApply" */));
//const ReferAFriend = React.lazy(() => import('./components/refer-a-friend/ReferFriend' /* webpackChunkName: "ReferAFriend" */));

//const EmailSubscribe = React.lazy(() => import('./components/email-us/EmailSubscribe' /* webpackChunkName: "EmailSubscribe" */));
//const UopxContact = React.lazy(() => import('./components/email-us/UopxContact' /* webpackChunkName: "UopxContact" */));
//const AlumniContactForm = React.lazy(() => import('./components/email-us/AlumniContactForm' /* webpackChunkName: "AlumniContactForm" */));
//const AlumniStory = React.lazy(() => import('./components/email-us/AlumniStory' /* webpackChunkName: "AlumniStory" */));
//const DynamicRFIInlineForm = React.lazy(() => import('./components/inline/DynamicRFIInlineForm' /* webpackChunkName: "DynamicRFIInlineForm" */));
//const EmailSubscribeFooter = React.lazy(() => import('./components/email-us/EmailSubscribeFooter' /* webpackChunkName: "EmailSubscribeFooter" */));

//const InternationalStudentRFI = React.lazy(() => import('./components/international-student/InternationalStudent' /* webpackChunkName: "InternationalStudentRFI" */));
//const ContinuingEducation = React.lazy(() => import('./components/continuing-education/ContinuingEducation' /* webpackChunkName: "ContinuingEducation" */));
//const MRFIDynamic5Step = React.lazy(() => import('./components/MobileRFI/MRfiDynamicFiveStep' /* webpackChunkName: "MRfiDynamicFiveStep" */));
//const MRFIScheduledCall = React.lazy(() => import('./components/MobileRFI/MrfiScheduleCall' /* webpackChunkName: "MrfiScheduleCall" */));
//const Summit = React.lazy(() => import('./components/email-us/SummitEmail' /* webpackChunkName: "SummitEmail" */));

//const AapfRfi = React.lazy(() => import('./components/AAPFRfi' /* webpackChunkName: "AAPFRfi" */));
//const RFIDatePicker = React.lazy(() => import('./components/RFIDatePicker' /* webpackChunkName: "RFIDatePicker" */));
//const AlumniBusinessForm = React.lazy(() => import('./components/email-us/AlumniBusinessForm' /* webpackChunkName: "AlumniBusinessForm" */));
//const White2Step = React.lazy(() => import('./components/inline/White2Step' /* webpackChunkName: "White2Step" */));
//const ReEntryContactUs = React.lazy(() => import('./components/email-us/ReEntryContactUs' /* webpackChunkName: "ReEntryContactUs" */));
//const AlumniChroniclesMagazine = React.lazy(() => import('./components/email-us/AlumniChroniclesMagazine' /* webpackChunkName: "AlumniChroniclesMagazine" */));

//const EmailSubscribeFooter = React.lazy(() => import('./components/email-us/EmailSubscribeFooter' /* webpackChunkName: "EmailSubscribeFooter" */));
//const RFIAbandonment = React.lazy(() => import('./components/email-us/EmailSubscribe' /* webpackChunkName: "RFIAbandonment" */));
//const Abandoned = React.lazy(() => import('./components/email-us/Abandoned' /* webpackChunkName: "Abandoned" */));
//const AbandonedWithName = React.lazy(() => import('./components/email-us/AbandonedWithName' /* webpackChunkName: "AbandonedWithName" */));

function App() {
  return (
    <ThemeProvider theme={theme}>
      <div className="RFIReactApp">
      <StylesProvider injectFirst generateClassName={generateClassName}>
        <React.Suspense fallback={<div>Loading...</div>}>
        
        <Routes>
          <Route path="/request/request-information" element={<DynamicDesktopRFI/>} /> 
          <Route path="/request/rfi" element={<DesktopRFI/>} />
          <Route path="/request/rfi-humanizing" element={<RFIHumanizing/>} />         
          <Route path="/request/info-call" element={<RFIDatePicker/>} />
          <Route path="/request/sch-dropdown" element={<ScheduleACallDropdown/>} />
          <Route path="/request/schedule-a-call" element={<ScheduleACall/>} />
          <Route path="/request/m-request-information" element={<MRFIDynamic5Step/>} />
          <Route path="/request/m-info-call" element={<MRFIScheduledCall/>} />                   
          <Route path="/request/refer-a-friend" element={<ReferAFriend/>} />
          <Route path="/request/email-us" element={<Emailus/>} />
          <Route path="/request/email-nurture" element={<ReEntryContactUs/>} />
          <Route path="/request/email-nurture-nd" element={<ReEntryContactUs/>} />
          <Route path="/request/contact" element={<UopxContact/>} />
          <Route path="/request/alumni-contact" element={<AlumniContactForm/>} />
          <Route path="/request/alumni-story" element={<AlumniStory/>} />
          <Route path="/request/continuing-education" element={<ContinuingEducation/>} />
          <Route path="/request/alumnibusinessform" element={<AlumniBusinessForm/>} />
          <Route path="/request/international-student" element={<InternationalStudentRFI/>} /> 
          <Route path="/request/abandoned" element={<Abandoned/>} />
          <Route path="/request/businessguideinline" element={<BusinessGuideInline/>} />
          <Route path="/request/businessguide" element={<BusinessGuide/>} />
          <Route path="/request/businessguideabandonment" element={<BusinessGuideAbandonment/>} />
          <Route path="/request/transfercreditguide" element={<TransferCreditCollegeGuideForm/>} />
          <Route path="/request/transfercreditguideabandonment" element={<TransferCreditGuideAbandonment/>} />
          <Route path="/request/rfiabandoned" element={<RFIAbandoned/>} />
          <Route path="/request/scholarshipsinline" element={<ScholarshipInline/>} />
          <Route path="/request/scholarshipsblademodal" element={<ScholarshipBladeModal/>} />
          <Route path="/request/scholarshipsexitmodal" element={<ScholarshipExitModal/>} />
          <Route path="/request/0p" element={<ZeroPartyModal/>} />
          <Route path="/request/footer-email-subscribe" element={<EmailSubscribeFooter/>} />
          <Route path="/request/subscribe-email" element={<SubscribeEmailForm /*theme="darkmode"*/ />} />
          <Route path="/request/apply" element={<SimpleApplyNow2StepSmarty/>} />
          <Route path="/request/unsubscribe" element={<IndividualEmailUnsubscribe/>} />
          <Route path="/request/communication/preferences"  element={<UnsubscribeForms/>}/>
          <Route path="/request/Opt-Out"  element={<OptOutForms/>}/>
          <Route path="/request/summit" element={<Summit/>} />
          <Route path="/request/aapf" element={<AapfRfi/>} />
          <Route path="/request/re-entry" element={<ReEntryContactUs/>} />
          <Route path="/request/alumnichroniclesmagazine" element={<AlumniChroniclesMagazine/>} />
	        <Route path="/request/CommunityCollegeTransfer" element={<CommunityCollegeTransferForm/>}/>
          <Route path="/request/employerreferralform" element={<EmployerReferralForm/>}/>
          <Route path="/request/inline-dynamic" element={<DynamicRFIInlineForm theme="twostep"/>}/>
          <Route path="/request/inline-employer" element={<EmployerDynamicRFIInlineForm theme="twostep"/>}/>
          <Route path="/request/paidmedia-employer" element={<PaidMediaEmployerRFIInlineForm theme="twostep"/>}/>
          <Route path="/request/white-2step" element={<White2Step fieldsEnabled="isScholarship" theme="twostep"/>} />    
          <Route path="/request/wsg" element={<WSGEmailPhoneCaptureForm/>} /> 
          <Route path="/request/gc" element={<GatedContentForm/>} /> 
          <Route path="/request/bgve" element={<ZeroPartyBGVE/>} />
          <Route path="/request/tcgve" element={<TransferCreditGuideVE/>} />
          <Route path="/request/Phoenixluminary" element={<PhoenixLuminary/>} />       

{/*          <Route path="/request/inline-twostep" element={<RFIInlineForm
                fieldsEnabled="isCBE"
                fieldOfStudyOptions={[
                  { label: 'Technology', value: 'technology' },
                  { label: 'Business', value: 'business' },
                  { label: 'Health Administration', value: 'healthcare' },
                ]}
                theme="twostep"
              />}/>   */}
          <Route path="*" element={<Navigate replace to="/request/request-information"/>} />
        </Routes>
        </React.Suspense>  
      </StylesProvider>
    </div>
    </ThemeProvider>
    
  );
}

export default App;